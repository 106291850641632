import React from "react"
import SEO from "../components/utils/SEO"
import Layout from "../components/layout/Layout"
import { Link } from "gatsby"
import LayoutWithImage from "../components/layout/LayoutWithImage"

const IndexPage = () => (
  <Layout>
    <SEO title="Startseite" />

    <div className="pt-5 header">
      <h1>Über uns</h1>
      <h2>Herzlich Willkommen bei &laquo;sichtwechsel&raquo; - Vernetzungsgruppe für Menschen mit Behinderung und Unterstützungsbedarf.</h2>
    </div>

    <LayoutWithImage>
      <h3 className="mt-0">sichtwechsel</h3>
      <p>
        &laquo;sichtwechsel&raquo; setzt sich für Menschen mit Behinderungen ein.
      </p>
      <p>
        &laquo;sichtwechsel&raquo; trifft sich regelmässig und tauscht Informationen aus.
      </p>
      <p>
        &laquo;sichtwechsel&raquo; macht jedes Jahr eine gemeinsame Aktion. Sie findet immer am 3. Dezember statt.
      </p>
      <p>
        <Link to="/vernetzungsgruppe">Hier finden Sie alle Organisationen</Link>, die bei &laquo;sichtwechsel&raquo; dabei sind.
      </p>

      <h3>Trailer im Skino</h3>
      <p>2020 hat &laquo;sichtwechsel&raquo; zusammen mit Arno Oehri zwei Trailer für das Skino in Schaan gemacht.</p>
      <p>Ein Trailer ist ein kurzes Video und wird im Kino vor dem Hauptfilm gezeigt.</p>
      <p>Die &laquo;sichtwechsel&raquo;-Trailer sind ein ganzes Jahr im Skino in Schaan zu sehen.</p>
      <p>
        <Link to="/film">Hier finden Sie die Trailer</Link> und den Film zur &laquo;blickwechsel&raquo;-Ausstellung von 2010.
      </p>

      <h3>Tag der Menschen mit Behinderungen</h3>
      <p>
        Am 3. Dezember findet jedes Jahr der Internationale Tag der Menschen mit Behinderungen statt.</p>
      <p>&laquo;sichtwechsel&raquo; plant und organisiert eine Aktion zu diesem Tag.</p>
    </LayoutWithImage>
    
  </Layout>
)

export default IndexPage
